import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userLevel, setUserLevel] = useState(() => {
    return localStorage.getItem('userLevel') ? parseInt(localStorage.getItem('userLevel')) : null;
  });
  const [username, setUsername] = useState(() => {
    return localStorage.getItem('username') || null;
  });

  useEffect(() => {
    if (userLevel !== null) {
      localStorage.setItem('userLevel', userLevel.toString());
    }
    if (username !== null) {
      localStorage.setItem('username', username);
    }
  }, [userLevel, username]);

  const login = (level, name) => {
    setUserLevel(level);
    setUsername(name);
    localStorage.setItem('userLevel', level.toString());
    localStorage.setItem('username', name);
  };

  const logout = () => {
    setUserLevel(null);
    setUsername(null);
    localStorage.removeItem('userLevel');
    localStorage.removeItem('username');
    localStorage.removeItem('token');

    window.location.href = '/login';
  };

  return (
    <UserContext.Provider value={{ userLevel, username, setUserLevel, setUsername, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
