import React, { useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { useUser } from '../pages/UserContext'; // Pastikan path sesuai
import '../App.css';
import { logo } from '../assets';

function NavbarMain() {
  const [showDropdown, setShowDropdown] = useState(false);
  const { username, logout } = useUser();

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  return (
    <Navbar expand="lg" style={{ backgroundColor: '#000060', position: 'fixed', width: '100%', zIndex: '1000' }} variant='dark'>
      <Container>
        <img src={logo} alt="logo" width="40" height="50"/>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{ backgroundColor: '#000060' }} className="justify-content-end">
          <Nav className="me-auto" style={{ backgroundColor: '#000060' }}>
            <Nav.Link className="nav-link-custom" href="/Home" style={{ fontSize: '18px', fontWeight: 'bold', color: 'white', marginLeft: '30px' }}>Beranda</Nav.Link>
            <div 
              className="nav-link-custom" 
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave} 
              style={{ position: 'relative' }}
            >
              <Nav.Link
                href="#"
                style={{ fontSize: '18px', fontWeight: 'bold', color: 'white', marginLeft: '20px' }}
              >
                Tentang
              </Nav.Link>
              {showDropdown && (
                <NavDropdown
                  title=""
                  id="profile-nav-dropdown"
                  className="nav-link-custom navbar-dropdown"
                  show={showDropdown}
                  style={{ position: 'unset', top: '100%', left: '0', zIndex: '100', backgroundColor: '#000060' }}
                >
                  <NavDropdown.Item href="/Profile">Profil</NavDropdown.Item>
                  <NavDropdown.Item href="/Location">Lokasi</NavDropdown.Item>
                </NavDropdown>
              )}
            </div>
            <Nav.Link className="nav-link-custom" href="/Shop" style={{ fontSize: '18px', fontWeight: 'bold', color: 'white', marginLeft: '20px' }}>Belanja</Nav.Link>
            <Nav.Link className="nav-link-custom" href="/Gallery" style={{ fontSize: '18px', fontWeight: 'bold', color: 'white', marginLeft: '20px' }}>Galeri</Nav.Link>
          </Nav>
          <Nav style={{ backgroundColor: 'rgba(0, 0, 0, 0)', borderRadius: '10px' }}>
            {username ? (
              <Nav.Link className="nav-link-custom" href="#" style={{ fontSize: '18px', fontWeight: 'bold', color: 'white' }} onClick={logout}>
                <FontAwesomeIcon icon={faSignOut} style={{ marginRight: '10px' }} />
                {username}
              </Nav.Link>
            ) : (
              <Nav.Link className="nav-link-custom" href="/login" style={{ fontSize: '18px', fontWeight: 'bold', color: 'white' }}>
                <FontAwesomeIcon icon={faSignIn} style={{ marginRight: '10px' }} />
                Sign In
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarMain;
